import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useBasicsStore } from "../../configs/stores/basics";
import { useAuthStore } from "../../configs/stores/auth";

const BasicsProvider = ({ children = <div /> }) => {
  const { isAuthenticated } = useAuthStore();
  const { getAll } = useBasicsStore();
  useQuery({
    enabled: !isAuthenticated,
    queryFn: getAll,
    queryKey: ["basics"],
    staleTime: 5 * 60 * 1000, // 2 minutes in milliseconds
    refetchInterval: 5 * 60 * 1000, // Refetch every 2 minutes
  });
  return (
    <React.Fragment>
      <>{children}</>
    </React.Fragment>
  );
};

export default BasicsProvider;
