import clsx from "clsx";
import { Edit, UserRoundCheck } from "lucide-react";
import React from "react";
import MissionaryUpdateModal from "../../../../../components/modals/models/missionary_update";
import { UserGrade } from "../../../../../configs/stores/admindataStore";

type Props = {
  row?: any;
};

const UserActions = ({ row }: Props) => {
  const [isOpenModify, setisOpenModify] = React.useState(false);
  function closeModify() {
    setisOpenModify(false);
  }
  return (
    <React.Fragment>
      <div className="flex flex-col">
        {[UserGrade.ASPIRANT, UserGrade.PARTICIPANT].includes(row.grade) && (
          <div
            onClick={() => {
              setisOpenModify(true);
            }}
            className={clsx(
              "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
              "hover:bg-slate-500/20 active:bg-slate-500/40 "
            )}
          >
            <UserRoundCheck size={18} />
            <span className="flex text-black font-medium text-[15px]">
              Valider le statut du missionnaire
            </span>
          </div>
        )}
        {/* {[UserGrade.ASPIRANT, UserGrade.PARTICIPANT].includes(row.grade) && (
          <div
            onClick={() => {
              setisOpenModify(true);
            }}
            className={clsx(
              "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
              "hover:bg-slate-500/20 active:bg-slate-500/40 "
            )}
          >
            <KeyRound size={18} />
            <span className="flex text-black font-medium text-[15px]">
              Transmettre ses accès
            </span>
          </div>
        )} */}
        <div
          onClick={() => {
            setisOpenModify(true);
          }}
          className={clsx(
            "flex cursor-pointer flex-row gap-[8px] h-[40px] w-full items-center rounded-[8px] px-3",
            "hover:bg-slate-500/20 active:bg-slate-500/40 "
          )}
        >
          <Edit size={18} />
          <span className="flex text-black font-medium text-[15px]">
            Modifier
          </span>
        </div>
      </div>
      <MissionaryUpdateModal
        open={isOpenModify}
        item={row.item}
        handleClose={closeModify}
      />
    </React.Fragment>
  );
};

export default UserActions;
