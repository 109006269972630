import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { getAllBasic, getMissionary } from "./functions/basics";
import {
  ChurchModel,
  CountryModel,
  DepartmentModel,
  MdvieOfficeModel,
  MissionModel,
} from "../../models/models";

const LOCAL_DATASTORE_NAME = "missionary-basics";

type ContextProps = {
  isLoading: boolean;
  langs: Array<any>;
  countries: Array<CountryModel>;
  mdvieOffices: Array<MdvieOfficeModel>;
  missions: Array<MissionModel>;
  churchs: Array<ChurchModel>;
  departments: Array<DepartmentModel>;
  getAll: () => Promise<any>;
  getMissionary: (id: string) => Promise<any>;
};

export const useBasicsStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      langs: [],
      missions: [],
      countries: [],
      mdvieOffices: [],
      churchs: [],
      departments: [],
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getAllBasic();
        const data = res?.data;
        set(
          produce((state: ContextProps) => {
            state.langs = data?.langs ?? [];
            state.missions = data?.missions ?? [];
            state.countries = data?.countries ?? [];
            state.mdvieOffices = data?.mdvieOffices ?? [];
            state.departments = data?.departments ?? [];
            state.churchs = data?.churchs ?? [];
            state.isLoading = false;
          })
        );
        return data;
      },
      getMissionary: async (id) => {
        const res = await getMissionary(id);
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_bsc")),
    }
  )
);
