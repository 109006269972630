import React from "react";
import ScreenHeader, {
  ScreenHeaderActionRefresh,
} from "../../../../../components/screen_header";
import ScreenContentLayout from "../../../../../components/screen_content_layout";
import TableDataAutoList from "../../../../../components/table";
import { useAdminDataStore } from "../../../../../configs/stores/admindataStore";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../../../configs/stores/auth";
import UserActions from "./actions";

const DashUsersScreen = () => {
  const { isAuthenticated } = useAuthStore();
  const { users, isRefetchingUsers, refetchUsers } = useAdminDataStore();
  useQuery({
    enabled: isAuthenticated,
    queryFn: refetchUsers,
    queryKey: ["users"],
    staleTime: 7 * 60 * 1000, // 5 minutes in milliseconds
    refetchInterval: 10 * 60 * 1000, // 5 minutes in milliseconds
  });
  const columns = [
    {
      label: "Nom & Prénoms",
      slug: "name",
    },
    {
      label: "Contact",
      slug: "phone",
    },
    {
      label: "Adresse email",
      slug: "email",
    },
    {
      label: "Pays",
      slug: "country",
    },
    {
      label: "Église",
      slug: "church",
    },
    {
      label: "Département",
      slug: "department",
    },
    {
      label: "Grade",
      slug: "grade",
    },
  ];
  return (
    <React.Fragment>
      <div className="flex flex-1 flex-col h-full w-full">
        <ScreenHeader title="Missionnaires">
          <ScreenHeaderActionRefresh
            onClick={refetchUsers}
            isLoading={isRefetchingUsers}
          />
        </ScreenHeader>
        <ScreenContentLayout>
          <TableDataAutoList
            columns={columns}
            rows={users.map((e) => ({
              item: e,
              ...e,
              name: e.firstname + " " + e.lastname,
              country: e?.country?.name ?? "",
              department: e?.department?.name ?? "",
              church:
                e?.church?.name ??
                (e?.otherchurch ? "Autre: " + e.otherchurch : null) ??
                "",
            }))}
            actions={UserActions}
          />
        </ScreenContentLayout>
      </div>
    </React.Fragment>
  );
};

export default DashUsersScreen;
